import { Dialog } from "@ariakit/react";
import { useNavigate } from "@remix-run/react";
import { Coins, X } from "lucide-react";
import React from "react";
import { erc20Abi, parseEther } from "viem";
import {
  useAccount,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import * as toast from "~/components/toast";
import * as config from "~/config";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useSupportedChains } from "~/hooks/use-supported-chains";
import { useUserWallets } from "~/hooks/use-user-wallets";
import { SwitchChain } from "~/routes/_app.sticker.$id";
import { cn } from "~/util/css";
import { Button } from "./button";

interface TipPostModalProps {
  postId: string;
  postOwnerAddress: string;
  postOwnerId: string;
  open: boolean;
  onClose: () => void;
  onTipSuccess?: () => void;
}

export function TipPostModal(props: TipPostModalProps) {
  const [amount, setAmount] = React.useState(0.001);
  const [currentUser] = useCurrentUser();
  const { userWallets } = useUserWallets();
  const { supportedChains } = useSupportedChains();
  const { address, chain, isConnected } = useAccount();

  const isChainSupported = supportedChains.some(
    (supportedChain) => supportedChain.chain_id === chain?.id,
  );

  const { writeContract, isPending, data: hash, error } = useWriteContract();
  const { switchChain } = useSwitchChain();
  const navigate = useNavigate();

  const chainId = isChainSupported ? (chain?.id ?? 84532) : 84532;
  const { popTokenAddress, buyLink } = React.useMemo(
    () => config.chainAddresses[chainId],
    [chainId],
  );
  const { isLoading, isSuccess, isError } = useWaitForTransactionReceipt({
    chainId: chain?.id,
    hash,
    confirmations: config.purchaseConfirmationCount,
  });
  const handleTipPost = () => {
    if (!isConnected) {
      return toast.fail({
        id: "toast",
        text: "Please connect a wallet first",
        action: {
          label: "Connect Wallet",
          onClick: () => navigate("/wallets"),
        },
      });
    }
    // Check if connected address is present in user profile or not
    if (
      !userWallets.some((wallet) => wallet.address === address) &&
      currentUser?.externalId !== address
    ) {
      return toast.fail({
        id: "toast",
        text: "Please add your connected wallet address to your account first",
        action: {
          label: "Add Address",
          onClick: () => navigate("/wallets"),
        },
        duration: 10000,
      });
    }

    if (!isChainSupported) {
      return switchChain({ chainId: supportedChains[0]?.chain_id });
    }

    writeContract({
      address: popTokenAddress,
      abi: erc20Abi,
      functionName: "transfer",
      args: [props.postOwnerAddress, parseEther(amount.toString())],
    });
  };

  const toastAction = {
    label: "View Tx",
    onClick: () =>
      window.open(
        `${chain?.blockExplorers?.default?.url}/tx/${hash}`,
        "_blank",
      ),
  };

  React.useEffect(() => {
    if (isError || error) {
      const errorMessage = error.message;
      const insufficientFundsError =
        errorMessage.includes("0xe450d38c") ||
        errorMessage.includes("transfer amount exceeds balance") ||
        errorMessage.includes(
          'An unknown error occurred while executing the contract function "transfer"',
        ) ||
        errorMessage.includes("Execution reverted for an unknown reason");

      if (insufficientFundsError) {
        toast.fail({
          id: "toast",
          title: "Insufficient funds",
          text: `Add ${config.defaultCurrency} to purchase`,
          duration: 5000,
          action: buyLink
            ? {
                label: `Buy ${config.defaultCurrency}`,
                onClick: () => window.open(buyLink, "_blank"),
              }
            : undefined,
        });
      } else if (!errorMessage.includes("User rejected the request")) {
        toast.fail({
          text: "Error while sending tip",
          id: "toast",
        });
      }
    }
  }, [isError, error]);

  React.useEffect(() => {
    if (isSuccess) {
      props.onClose;
      toast.ok({ id: "toast", text: "Tip sent successfully" });
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (!hash) return;
    fetch("/api/tip-transaction", {
      method: "POST",
      body: JSON.stringify({
        postId: props.postId,
        toUserId: props.postOwnerId,
        tipAmount: amount,
        tipCurrency: config.defaultCurrency,
        txHash: hash,
        chainId: chain?.id,
      }),
    }).then((res) => {
      if (res.ok) {
        toast.loading({
          id: "toast",
          text: "Waiting for tx confirmations...",
          action: toastAction,
          duration: 10000,
        });
      } else {
        toast.fail({ id: "toast", text: "Error while sending tip" });
      }
    });
  }, [hash]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[500px] mx-auto bg-white rounded-xl border-2 border-black shadow-[10px_10px_0px_0px_#000]  mh-[calc(100vh-2rem)]",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 lg:p-0 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="flex flex-col">
        <div className="absolute w-10 h-10 -top-4 -right-4 rounded-xl flex justify-center  bg-white z-20 border-2 border-black">
          <button type="button" onClick={props.onClose}>
            <X />
          </button>
        </div>
        <div className="p-8 px-10 flex flex-col gap-4">
          <div className="justify-between items-start gap-6 inline items-center flex">
            <div className="flex gap-2 items-center ">
              <input
                min="0.001"
                max="1000"
                step="0.01"
                type="number"
                className={cn(
                  "w-24 p-2 focus:outline-none flex border-lg border-2 border-gray-200 rounded-md",
                )}
                name="amount"
                onChange={(e) => setAmount(Number.parseFloat(e.target.value))}
                value={amount}
              />{" "}
              {config.defaultCurrency}
            </div>
            {isChainSupported ? (
              <Button
                className="flex border rounded-full border-[#6637CE] h-[52px] w-44 font-medium bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,#CBE3FF_18.75%,#FFA8DC_50%,#FFF_96.35%)]"
                onClick={() => handleTipPost()}
                disabled={isPending || isLoading}
                type="button"
              >
                <Coins />
                <strong>Tip Post</strong>
              </Button>
            ) : (
              <SwitchChain chains={supportedChains} />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

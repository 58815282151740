import { Dialog, DialogDismiss } from "@ariakit/react";
import {
  PopoverDisclosure,
  PopoverProvider,
  usePopoverStore,
} from "@ariakit/react";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Link, useLoaderData, useNavigate } from "@remix-run/react";
import { format } from "date-fns";
import { Plus, X } from "lucide-react";
import pluralize from "pluralize";
import React from "react";
import { toast } from "sonner";
import { erc20Abi, parseEther } from "viem";
import {
  useAccount,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { z } from "zod";
import { Button } from "~/components/button";
import { CollectorCard, CollectorsModal } from "~/components/collectors";
import { LazyLottie } from "~/components/lazy-lottie";
import { AnimatedPopover } from "~/components/post";
import { StickerCard, UsersIcon } from "~/components/sticker";
import * as config from "~/config";
import { db, sql } from "~/db";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useSupportedChains } from "~/hooks/use-supported-chains";
import { useUserWallets } from "~/hooks/use-user-wallets";
import type { Sticker } from "~/types";
import { cn } from "~/util/css";
import { getMetadata } from "~/util/metadata";
import { getCurrentUserFromSession } from "~/util/sessions/login";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  let metadata = getMetadata({
    image: `${data?.originUrl}/soulbound-social.webp`,
  });
  if (data?.sticker) {
    metadata = getMetadata({
      image: `${data?.originUrl}${data?.sticker.image_url}`,
      title: data?.sticker?.sticker_pack_name,
    });
  }
  return metadata;
};

interface Chain {
  name: string;
  chain_id: number;
}

export const idSchema = z.string().transform((val) => {
  const parsed = Number.parseInt(val);
  return Number.isNaN(parsed) || !Number.isSafeInteger(parsed) ? null : parsed;
});

export async function loader({ request, params }: LoaderFunctionArgs) {
  const stickerId = idSchema.parse(params.id);
  const url = new URL(request.url);
  if (!stickerId) {
    return {
      sticker: null,
      collectors: [],
      chains: [],
      isStickerOwnedByUser: false,
      originUrl: url.origin,
      moreStickers: [],
    };
  }
  const currentUser = await getCurrentUserFromSession(request);

  const sticker = await db
    .selectFrom("stickers")
    .innerJoin("sticker_packs", "stickers.sticker_pack_id", "sticker_packs.id")
    .innerJoin("users", "sticker_packs.owner_id", "users.id")
    .select([
      "stickers.id as id",
      "stickers.image_url as image_url",
      "stickers.created_at as created_at",
      "stickers.sticker_pack_id as sticker_pack_id",
      "stickers.price_amount as price_amount",
      "stickers.price_currency as price_currency",
      "sticker_packs.name as sticker_pack_name",
      "users.id as owner_id",
      "users.username as owner_username",
    ])
    .where("stickers.id", "=", stickerId)
    .where("stickers.deleted_at", "is", null)
    .executeTakeFirst();

  const collectors = await db
    .selectFrom("sticker_transactions")
    .innerJoin("users", "sticker_transactions.to_user_id", "users.id")
    .leftJoin(
      "user_quests",
      "user_quests.user_id",
      "sticker_transactions.to_user_id",
    )
    .select(({ fn }) => [
      "users.id as id",
      "users.avatar_url as avatar_url",
      "users.username as username",
      fn.sum<number>("user_quests.points").distinct().as("points"),
    ])
    .where("sticker_transactions.sticker_id", "=", stickerId)
    .where(
      "sticker_transactions.confirmations",
      ">=",
      config.purchaseConfirmationCount,
    )
    .groupBy("users.id")
    .orderBy("points", "desc")
    .execute();

  let isStickerOwnedByUser = false;
  if (currentUser) {
    isStickerOwnedByUser = collectors.some(
      (collector) => collector.id === currentUser.id,
    );
  }

  let moreStickers = sticker
    ? await db
        .selectFrom("stickers")
        .select(({ fn }) => [
          "stickers.id as id",
          "stickers.image_url as image_url",
          "stickers.price_amount as price_amount",
          "stickers.price_currency as price_currency",
          "sticker_packs.name as sticker_pack_name",
          "users.id as user_id",
          "users.username as user_username",
          "users.avatar_url as user_avatar_url",
          fn
            .count<number>("sticker_transactions.to_user_id")
            .distinct()
            .filterWhere("sticker_transactions.tx_hash", "is not", null)
            .filterWhere(
              "sticker_transactions.confirmations",
              ">=",
              sql`${config.purchaseConfirmationCount}`,
            )
            .as("purchase_count"),
        ])
        .leftJoin(
          "sticker_packs",
          "sticker_packs.id",
          "stickers.sticker_pack_id",
        )
        .leftJoin(
          "sticker_transactions",
          "sticker_transactions.sticker_id",
          "stickers.id",
        )
        .leftJoin("users", "users.id", "sticker_packs.owner_id")
        .where("stickers.deleted_at", "is", null)
        .where("stickers.sticker_pack_id", "=", sticker.sticker_pack_id)
        .groupBy([
          "stickers.id",
          "sticker_packs.name",
          "users.id",
          "users.username",
          "users.avatar_url",
        ])
        .orderBy("stickers.id", "desc")
        .execute()
    : [];

  const stickerOwnerIds =
    moreStickers.length > 0
      ? sql<number[]>`${sql.join(
          moreStickers.map((sticker) => +sticker.user_id),
        )}`
      : [];

  const stickerOwnerCountsQuery =
    moreStickers.length > 0
      ? await sql<{ id: number; sold_count: number; sticker_count: number }>`
      select
        users.id as id,
        count(sticker_transactions.from_user_id) as sold_count,
        coalesce(available_stickers.sticker_count, 0) as sticker_count
      from users
      left join sticker_transactions on sticker_transactions.from_user_id = users.id and sticker_transactions.tx_hash is NOT null
      left join (
        select
          coalesce(rs.user_id, ss.user_id) as user_id,
          count(distinct case when rs.sticker_id is not null then rs.sticker_id else ss.sticker_id end) as sticker_count
        from (
          select
            to_user_id as user_id,
            sticker_id
          from sticker_transactions
          where to_user_id in (${stickerOwnerIds}) and confirmations >= 4
        ) as rs
        full outer join (
          select
            from_user_id as user_id,
            sticker_id
          from sticker_transactions
          where from_user_id in (${stickerOwnerIds}) and confirmations >= 4
        ) as ss on rs.user_id = ss.user_id
        group by coalesce(rs.user_id, ss.user_id)
      ) as available_stickers on users.id = available_stickers.user_id
      where users.id in (${stickerOwnerIds})
      group by users.id, available_stickers.sticker_count
    `.execute(db)
      : undefined;

  moreStickers = moreStickers.map((sticker) => {
    return {
      ...sticker,
      owner_counts:
        stickerOwnerCountsQuery?.rows.find(
          (row) => row.id === sticker.user_id,
        ) || {},
    };
  });

  return {
    sticker,
    collectors,
    isStickerOwnedByUser,
    originUrl: url.origin,
    moreStickers,
  };
}

const MAX_COLLECTORS_DISPLAYED = 8;

export default function StickerPage() {
  const navigate = useNavigate();
  const loaderData = useLoaderData<typeof loader>();
  const sticker = loaderData.sticker;
  const [isStickerOwnedByUser, setIsStickerOwnedByUser] = React.useState(
    loaderData.isStickerOwnedByUser,
  );
  const [collectors, setCollectors] = React.useState(loaderData.collectors);
  const [collectorModalOpen, setCollectorModalOpen] = React.useState(false);
  const [stickersModalOpen, setStickersModalOpen] = React.useState(false);

  const stickerPriceAmount: string =
    sticker?.price_amount ?? config.defaultStickerPrice;
  const stickerPriceCurrency =
    sticker?.price_currency ?? config.defaultCurrency;
  const stickerPrice = `${stickerPriceAmount} ${stickerPriceCurrency}`;

  if (!sticker) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="text-2xl font-bold">Sticker not found</div>
      </div>
    );
  }

  const { address, chain, isConnected } = useAccount();

  const [currentUser] = useCurrentUser();
  const { switchChain } = useSwitchChain();

  const [toastId, setToastId] = React.useState<string | number>();
  const [isOpenSuccessModal, setIsOpenSuccessModal] = React.useState(false);

  const toastAction = {
    label: "View Tx",
    onClick: () =>
      window.open(
        `${chain?.blockExplorers?.default?.url}/tx/${hash}`,
        "_blank",
      ),
  };

  const { userWallets } = useUserWallets();
  const { supportedChains } = useSupportedChains();
  const isChainSupported = supportedChains.some(
    (supportedChain) => supportedChain.chain_id === chain?.id,
  );
  const chainId = isChainSupported ? (chain?.id ?? 84532) : 84532;

  const { popTokenAddress, buyLink, soulboundAccountAddress } = React.useMemo(
    () => config.chainAddresses[chainId],
    [chainId],
  );

  const { writeContract, isPending, data: hash, error } = useWriteContract();

  const { isLoading, isSuccess, isError } = useWaitForTransactionReceipt({
    chainId: chain?.id,
    hash,
    confirmations: config.purchaseConfirmationCount,
  });

  React.useEffect(() => {
    if (isSuccess) {
      fetch("/api/sticker-transaction", {
        method: "PATCH",
        body: JSON.stringify({
          txHash: hash,
          confirmations: config.purchaseConfirmationCount.toString(),
        }),
      });
      setIsOpenSuccessModal(true);
      toast.dismiss(toastId);
      setCollectors([
        ...collectors,
        {
          id: currentUser?.id,
          avatar_url: currentUser?.avatarUrl,
          username: currentUser?.username,
        },
      ]);
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (isError) {
      toast.error("Error while buying sticker", {
        id: toastId,
        action: toastAction,
        duration: 3000,
      });
    }
  }, [isError]);

  const initiateTransaction = () => {
    if (!currentUser?.id) {
      return toast.error("You must be signed in to buy sticker.", {
        action: {
          label: "Sign In",
          onClick: () => navigate("/login"),
        },
      });
    }
    if (!isConnected) {
      return toast.error("Please connect a wallet first", {
        action: {
          label: "Connect Wallet",
          onClick: () => navigate("/wallets"),
        },
      });
    }
    // Check if connected address is present in user profile or not
    if (
      !userWallets.some((wallet) => wallet.address === address) &&
      currentUser?.externalId !== address
    ) {
      return toast.error(
        "Please add your connected wallet address to your account first",
        {
          action: {
            label: "Add Address",
            onClick: () => navigate("/wallets"),
          },
          duration: 10000,
        },
      );
    }

    if (!isChainSupported) {
      return switchChain({ chainId: supportedChains[0]?.chain_id });
    }

    fetch(
      `/api/sticker-transaction?stickerId=${sticker.id}&toUserId=${currentUser?.id}&fromUserId=${sticker.owner_id}`,
    )
      .then((res) => res.json())
      .then(({ tx }) => {
        if (tx) {
          const isConfirmed =
            tx.confirmations > config.purchaseConfirmationCount;
          const isPending =
            tx.confirmations >= 0 &&
            tx.confirmations < config.purchaseConfirmationCount;

          if (isConfirmed) {
            setIsOpenSuccessModal(true);
            setIsStickerOwnedByUser(true);
            setCollectors([
              ...collectors,
              {
                id: currentUser?.id,
                avatar_url: currentUser?.avatarUrl,
                username: currentUser?.username,
              },
            ]);
          } else if (isPending) {
            toast.info(`Pending tx with ${tx.confirmations} confirmations`, {
              action: {
                label: "View Tx",
                onClick: () =>
                  window.open(`${tx.explorer_url}tx/${tx.tx_hash}`, "_blank"),
              },
            });
          }
        } else {
          writeContract({
            address: popTokenAddress,
            abi: erc20Abi,
            functionName: "transfer",
            args: [
              soulboundAccountAddress,
              parseEther(stickerPriceAmount.toString()),
            ],
          });
        }
      });
  };

  React.useEffect(() => {
    if (error) {
      const errorMessage = error.message;
      const insufficientFundsError =
        errorMessage.includes("0xe450d38c") ||
        errorMessage.includes("transfer amount exceeds balance") ||
        errorMessage.includes("Execution reverted for an unknown reason");

      if (insufficientFundsError) {
        toast.error("Insufficient funds", {
          description: `Add ${config.defaultCurrency} to purchase`,
          duration: 5000,
          action: buyLink
            ? {
                label: `Buy ${config.defaultCurrency}`,
                onClick: () => window.open(buyLink, "_blank"),
              }
            : undefined,
        });
      } else if (!errorMessage.includes("User rejected the request")) {
        toast.error("Something went wrong. Please try again later.");
      }
    }
  }, [error]);

  React.useEffect(() => {
    if (hash) {
      fetch("/api/sticker-transaction", {
        method: "POST",
        body: JSON.stringify({
          stickerId: sticker.id,
          priceAmount: stickerPriceAmount,
          priceCurrency: stickerPriceCurrency,
          txHash: hash,
          chainId: chain?.id,
        }),
      }).then((res) => {
        if (res.ok) {
          const toastId = toast.loading("Waiting for tx confirmations...", {
            action: toastAction,
            duration: 100000,
          });
          setToastId(toastId);
        } else {
          console.error("failed sticker tx hash", hash);
          toast.error("Error while buying sticker");
        }
      });
    }
  }, [hash]);

  return (
    <>
      <div className="w-full py-10 px-6 flex flex-col gap-11">
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-[480px]">
            <div className="border border-[rgba(139,100,189,0.64)] rounded-xl overflow-hidden p4">
              <div
                className="w-full max-h-96 lg:max-h-auto lg:w-full aspect-square rounded-lg bg-contain bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${sticker.image_url})`,
                }}
              />
            </div>
          </div>
          <div className="w-full flex-1 flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <Link
                to={`/sticker-pack/${sticker.sticker_pack_id}`}
                className="flex items-center gap-1.5"
              >
                <img
                  src="/icons/sticker-pack.svg"
                  alt="sticker pack"
                  className="w-4 h-4"
                />
                <h1 className="text-lg font-bold text-[#6637CE]">
                  {sticker.sticker_pack_name}
                </h1>
              </Link>
              <p className="text-base">
                Created by{" "}
                <span className="text-[#6637CE] font-bold">
                  {sticker.owner_username === "admin"
                    ? "Soulbound"
                    : sticker.owner_username}
                </span>
              </p>
            </div>

            <div className="flex flex-col gap-8 w-full">
              <div className="flex items-center gap-1">
                <span className="text-[#6637CE]">
                  <UsersIcon />
                </span>

                <p className="text-sm font-medium">
                  {collectors.length} {pluralize("claims", collectors.length)}
                </p>
              </div>
            </div>

            <div className="w-full bg-[#B999FF] h-[1px]" />

            <div className="flex items-center gap-3">
              {sticker.owner_username !== "admin" && (
                <div className="flex items-center justify-center  flex-col gap-1 px-6 py-2 h-16 rounded-lg border border-[#B999FF]">
                  <p className="text-sm text-[#5B6684] hidden lg:block">
                    Current price
                  </p>
                  <p className="text-lg text-[#6637CE] font-medium">
                    {stickerPrice}
                  </p>
                </div>
              )}
              {config.features.buyStickers &&
                sticker.owner_username !== "admin" &&
                (isConnected ? (
                  isChainSupported ? (
                    <>
                      {isStickerOwnedByUser || isSuccess ? (
                        <Button
                          className="flex border rounded-full border-[#6637CE] h-[52px] w-44 font-medium bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,#CBE3FF_18.75%,#FFA8DC_50%,#FFF_96.35%)]"
                          asChild
                        >
                          <Link to={`/${currentUser?.username}`}>
                            <span className="flex items-center justify-center gap-1">
                              Added to Collection
                            </span>
                          </Link>
                        </Button>
                      ) : (
                        <Button
                          className="flex border rounded-full border-[#6637CE] h-[52px] w-44 font-medium bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,#CBE3FF_18.75%,#FFA8DC_50%,#FFF_96.35%)]"
                          disabled={isLoading || isPending}
                          onClick={initiateTransaction}
                        >
                          {isLoading || isPending
                            ? "Confirming tx"
                            : stickerPrice}
                        </Button>
                      )}
                    </>
                  ) : (
                    <SwitchChain chains={supportedChains} />
                  )
                ) : (
                  <Button
                    className="flex border rounded-full border-[#6637CE] h-[52px] w-44 font-medium bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,#CBE3FF_18.75%,#FFA8DC_50%,#FFF_96.35%)]"
                    onClick={initiateTransaction}
                  >
                    Buy
                  </Button>
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 lg:flex-row lg:gap-20">
          <div className="w-56 flex flex-col gap-4 lg:gap-9">
            <p className="font-medium text-[#6637CE]">Details</p>
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <p className="text-sm">Created by</p>
                <p className="text-[#6637CE] font-bold text-sm">
                  {sticker.owner_username === "admin"
                    ? "Soulbound"
                    : sticker.owner_username}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm">Date of creation</p>
                <p className="text-[#6637CE] font-bold text-sm">
                  {format(new Date(sticker.created_at), "dd/MM/yyyy")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-4 lg:gap-6">
            <div className="flex items-center justify-between">
              <p className="font-medium text-[#6637CE]">Top Claimers</p>
              {collectors.length > MAX_COLLECTORS_DISPLAYED && (
                <button
                  onClick={() => setCollectorModalOpen(true)}
                  className="border border-[#6637CE] hover:bg-[#6637CE] hover:text-white rounded-full h-10 inline-flex gap-1 text-[#6637CE] font-medium items-center justify-center px-3"
                >
                  See more
                  <Plus size={16} />
                </button>
              )}
            </div>
            {collectors.length > 0 ? (
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                {collectors
                  .slice(0, MAX_COLLECTORS_DISPLAYED)
                  .map((collector, index) => (
                    <CollectorCard
                      key={collector?.username}
                      collector={collector}
                      index={index}
                    />
                  ))}
              </div>
            ) : (
              <p>No claimers yet.</p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <p className="font-medium text-[#6637CE]">
              More from this Collection
            </p>
            <button
              onClick={() => setStickersModalOpen(true)}
              className="border border-[#6637CE] hover:bg-[#6637CE] hover:text-white rounded-full h-10 inline-flex gap-1 text-[#6637CE] font-medium items-center justify-center px-3"
            >
              See more
              <Plus size={16} />
            </button>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4  lg:grid-cols-6 gap-6">
            {loaderData.moreStickers.slice(0, 6).map((sticker) => (
              <StickerCard sticker={sticker} key={sticker.id} />
            ))}
          </div>
        </div>
      </div>

      {stickersModalOpen && (
        <StickersModal
          stickers={loaderData.moreStickers}
          open={stickersModalOpen}
          onClose={() => setStickersModalOpen(false)}
        />
      )}

      {collectorModalOpen && (
        <CollectorsModal
          collectors={collectors}
          open={collectorModalOpen}
          onClose={() => setCollectorModalOpen(false)}
        />
      )}

      {currentUser && (
        <PurchaseCompleteModal
          open={isOpenSuccessModal}
          onClose={() => setIsOpenSuccessModal(false)}
          username={currentUser?.username}
        />
      )}
    </>
  );
}

interface PurchaseCompleteModalProps {
  open: boolean;
  onClose: () => void;
  username: string | null;
}

export function PurchaseCompleteModal(props: PurchaseCompleteModalProps) {
  return (
    <Dialog
      backdrop={false}
      open={props.open}
      onClose={props.onClose}
      className="relative w-full lg:w-[500px] mx-auto bg-white border-2 border-black overflow-auto mh-[calc(100vh-2rem)] shadow-[10px_10px_0px_0px_#000] rounded-xl"
      render={(dialogProps) => (
        <div
          className={cn(
            "fixed z-50 top-0 p-4 w-full h-full bg-pink-500/30 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
          onClick={(e) => e.preventDefault()}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="flex items-start p-4  gap-2">
        <div className="pt-0 flex-1">
          <div className="flex items-center gap-2">
            <LazyLottie
              getAnimationData={() =>
                import("public/lottie/cereal-pops-anim.json")
              }
              id="animation-box"
              loop={true}
              autoplay={true}
            />
            <div className="flex-1">
              <p className="text-2xl font-bold mb-5">
                Sticker purchased successfully!
              </p>
              <Link to={`/${props.username}`}>
                <Button className="px-4 py-2 border-2 border-black font-semibold rounded-full bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,_#CCFFCB_18.75%,_#9AC8FF_50%,_#FFF_96.35%)]">
                  Check my stickers
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <DialogDismiss className="outline-none">
          <X />
        </DialogDismiss>
      </div>
    </Dialog>
  );
}

interface SwitchChainProps {
  chains: Chain[];
  className?: string;
}

export function SwitchChain(props: SwitchChainProps) {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");
  const { switchChain } = useSwitchChain();

  return (
    <PopoverProvider store={popoverStore} placement="bottom-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
        render={
          <Button
            className={
              props.className
                ? props.className
                : "flex border rounded-full border-[#6637CE] h-[52px] w-44 font-medium bg-[radial-gradient(116.35%_116.25%_at_53.26%_0%,#FFF_0%,#CBE3FF_18.75%,#FFA8DC_50%,#FFF_96.35%)]"
            }
          />
        }
      >
        <span className="hidden sm:inline">Switch Network</span>
      </PopoverDisclosure>
      <AnimatedPopover mounted={mounted} className="w-60">
        <ul>
          {props.chains.map((chain) => (
            <li className="border-b" key={chain.chain_id}>
              <button
                className="hover:bg-gray-300 p-3 w-full flex items gap-2"
                onClick={() => {
                  switchChain({ chainId: chain.chain_id });
                }}
                type="button"
              >
                <img
                  src={`/chains/${chain.chain_id}.svg`}
                  alt="icon"
                  className="w-6 h-6"
                />
                <strong>{chain.name}</strong>
              </button>
            </li>
          ))}
        </ul>
      </AnimatedPopover>
    </PopoverProvider>
  );
}

interface StickersModalProps {
  open: boolean;
  onClose: () => void;
  stickers: Sticker[];
}

export function StickersModal(props: StickersModalProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[60%] mx-auto overflow-hidden max-h-[90%]",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="bg-white rounded-xl border-primary border h-full">
        <div className="flex items-center justify-between p-4">
          <p className="font-bold text-lg">Stickers</p>
          <button onClick={props.onClose}>
            <X />
          </button>
        </div>
        <div className="p-4 h-[calc(100%-60px)]">
          <div className=" overflow-y-auto h-full">
            <div className="grid grid-cols-5 gap-3">
              {props.stickers.map((sticker) => (
                <StickerCard key={sticker.id} sticker={sticker} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
